
import {
  onMounted,
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
} from "vue";
import {
  CreateExaminerHubCmd,
  GetAllRegionRep,
  GetByIdExaminerHubRep,
  ModifyExaminerHubCmd,
} from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message } from "ant-design-vue";
import { prop } from "vue-class-component";
import { Form } from 'ant-design-vue';

const useForm = Form.useForm;
export default defineComponent({
  props: {
    examinerHubDetail: { default: () => new GetByIdExaminerHubRep() },
    regionList: {
      type: Array as PropType<GetAllRegionRep[]>,
      default: () => [],
    },
  },

  setup(
    props: {
      examinerHubDetail: GetByIdExaminerHubRep;
      regionList: GetAllRegionRep[];
    },
    context: SetupContext
  ) {
    const formLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

    const confirmLoading = ref<boolean>(false);

    const state = reactive<{
      examinerHubForm: GetByIdExaminerHubRep;
      rulesRef: { [key: string]: any };
    }>({
      examinerHubForm: {
        ...props.examinerHubDetail,
      } as GetByIdExaminerHubRep,
      rulesRef: {
        capacity: [
          {
            required: true,
            message: "Please input capacity（1-1000）",
            type: "number",
          },
          { validator: validateHubCapacity },
        ],
        address: [
          {
            required: true,
            message: "please input address（1-256）",
          },
        ],
        isHomeWorking: [
          {
            required: true,
            message: "please select home working",
          },
        ],
      },
    });

    const { resetFields, validate, validateInfos } = useForm(
      state.examinerHubForm,
      state.rulesRef
    );

    const handleModifyHub = async () => {
      try {
        await validate();
        confirmLoading.value = true;
        let modifyExaminerHubModel: ModifyExaminerHubCmd = new ModifyExaminerHubCmd(
          {
            id: state.examinerHubForm.id,
            capacity: state.examinerHubForm.capacity,
            hubName: state.examinerHubForm.hubName!,
            abbreviation: state.examinerHubForm.abbreviation!,
            address: state.examinerHubForm.address!,
            isEnable: state.examinerHubForm.isEnable,
            isHomeWorking: state.examinerHubForm.isHomeWorking
          }
        );
        await _Client.examinerHubClient.modify(modifyExaminerHubModel);
        message.success("success");
        close(true);
      } catch {
      } finally {
        confirmLoading.value = false;
      }
    };
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }

    return {
      formLayout,
      confirmLoading,
      ...toRefs(state),
      handleModifyHub,
      validate,
      validateInfos,
      resetFields,
      close,
    };
  },
});
