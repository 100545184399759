
import {
  onMounted,
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
} from "vue";
import { examinerHubStatus } from "@/api-client/const";
import { CreateExaminerHubCmdForm } from "@/api-client/modal";
import { CreateExaminerHubCmd, GetAllRegionRep } from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message ,Form} from "ant-design-vue";

const useForm = Form.useForm;
export default defineComponent({
  props: {
    regionList: {
      type: Array as PropType<GetAllRegionRep[]>,
      default: () => [],
    },
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    const hubStatus = examinerHubStatus;
    const formLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

    const isShowCreateDialog = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);

    const state = reactive<{
      examinerHubForm: CreateExaminerHubCmdForm;
      rulesRef: { [key: string]: any };
    }>({
      examinerHubForm: {
        officeId: "",
        cityId: "",
        hubName: "",
        abbreviation: "",
        address: "",
        capacity: undefined,
        isEnable: true,
        centerNo: undefined,
        cityName: undefined,
        isHomeWorking: undefined,
      },
      rulesRef: {
        officeId: [{ required: true, message: "Please select region" }],
        capacity: [
          {
            required: true,
            message: "Please input capacity（1-1000）",
            type: "number",
          },
        ],
        hubName: [
          {
            required: true,
            message: "please input hubName（1-100）",
          },
        ],
        abbreviation: [
          {
            required: true,
            message: "please input abbreviation（1-100）",
          },
        ],
        address: [
          {
            required: true,
            message: "please input address（1-256）",
          },
        ],
        isHomeWorking: [
          {
            required: true,
            message: "please select home working",
          },
        ],
      },
    });
    const { resetFields, validate, validateInfos } = useForm(
      state.examinerHubForm,
      state.rulesRef
    );

    function handleRegionChange() {
      props.regionList.forEach((item: GetAllRegionRep) => {
        if (item.id === state.examinerHubForm.officeId) {
          state.examinerHubForm.cityName = item.cityName!;
          state.examinerHubForm.cityId = item.cityId!;
          state.examinerHubForm.centerNo = item.centerNo!;
        }
      });
    }
    const handleCreateHub = async () => {
      try {
        await validate();
        confirmLoading.value = true;
        await _Client.examinerHubClient.create(
          new CreateExaminerHubCmd({
            officeId: state.examinerHubForm.officeId,
            cityId: state.examinerHubForm.cityId,
            hubName: state.examinerHubForm.hubName,
            abbreviation: state.examinerHubForm.abbreviation,
            address: state.examinerHubForm.address,
            capacity: state.examinerHubForm.capacity!,
            isEnable: state.examinerHubForm.isEnable,
            isHomeWorking: state.examinerHubForm.isHomeWorking!,
          })
        );
        message.success("success");
        isShowCreateDialog.value = false;
        context.emit("getList");
      } catch {
      } finally {
        confirmLoading.value = false;
      }
    };

    return {
      hubStatus,
      formLayout,
      isShowCreateDialog,
      confirmLoading,
      ...toRefs(state),
      handleRegionChange,
      handleCreateHub,
      validate,
      validateInfos,
      resetFields,
    };
  },
});
