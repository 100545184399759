// examiner hub
// examiner hub capacity
export const validateHubCapacity = (rule: any, value: string, callback: Function) => {
  let reg = /^(?:[0-9]{1,3}|1000)$/
  if (!reg.test(value)) {
    return Promise.reject('Please enter a positive integer (1-1000)')
  }
  return Promise.resolve();
}

//resource planning
export const validPositiveInteger = (e: any) => {
  // 此处还可以限制位数以及大小
  return e.target.value.replace(/[^0-9]/gi, '')
}

