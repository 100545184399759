import { _Client } from '@/api-client';
import { GetAllRegionRep, GetByIdExaminerHubRep, ModifyExaminerHubCmd, PagedResultOfSearchExaminerHubLogRep, PagedResultOfSearchExaminerHubRep, SearchExaminerHubLogQuery, SearchExaminerHubQuery, SearchExaminerHubRep } from '@/api-client/client';
import { message } from "ant-design-vue";
import { defineComponent, getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import CreateExaminerHub from './components/AddExaminerHub.vue'
import EditExaminerHub from './components/EditExaminerHub.vue'
import Pagination from '@/components/Pagination/index.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import moment from "moment-timezone"
import { checkAccess } from '@/utils/common';
import { LogDetailModel, LogModel } from '@/api-client/modal';

export default defineComponent({
  components: {
    CreateExaminerHub,
    EditExaminerHub,
    Pagination,
    LogDetail
  },
  setup() {
    const momentTimeZone = moment
    const internalInstance = getCurrentInstance();
    const statusList = [
      { id: true, name: 'Enable' },
      { id: false, name: 'Disable' }
    ]
    const columns = [
      {
        title: 'Region',
        dataIndex: 'regionName',
        key: 'regionName',
        width: 120
      },
      {
        title: 'Center No.',
        dataIndex: 'centerNumber',
        key: 'centerNumber',
        width: 100
      },
      {
        title: 'City',
        dataIndex: 'cityName',
        key: 'cityName',
        width: 110
      },
      {
        title: 'Hub Name',
        dataIndex: 'hubName',
        key: 'hubName',
        width: 150
      },
      {
        title: 'Address',
        key: 'address',
        dataIndex: 'address',
        width: 200
      },
      {
        title: 'Abbreviation',
        key: 'abbreviation',
        dataIndex: 'abbreviation',
        width: 120
      },
      {
        title: 'Capacity',
        key: 'capacity',
        dataIndex: 'capacity',
        width: 100
      },
      {
        title: 'Status',
        key: 'status',
        slots: { customRender: 'status' },
        width: 120
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
        width: 200
      }
    ]

    const tableLoading = ref<boolean>(false)

    const listState = reactive<{
      regionList: GetAllRegionRep[],
      searchParames: SearchExaminerHubQuery,
      tableSource: PagedResultOfSearchExaminerHubRep
    }>({
      regionList: Array<GetAllRegionRep>(),
      searchParames: new SearchExaminerHubQuery({
        officeId: undefined,
        isEnable: undefined,
        pageIndex: 1,
        pageSize: 10,
      }),
      tableSource: new PagedResultOfSearchExaminerHubRep(),
    })

    async function getRegionList() {
      listState.regionList = await _Client.regionClient.getAll(true)
    }
    async function getList() {
      try {
        tableLoading.value = true
        listState.tableSource = await _Client.examinerHubClient.search(listState.searchParames)
      }
      finally {
        tableLoading.value = false
      }
    }
    const changeSearchParames = async () => {
      listState.searchParames.pageIndex = 1
      await getList()
    };
    async function resetSearchParams() {
      listState.searchParames = new SearchExaminerHubQuery()
      await getList()
    };
    async function disableHub(examinerHub: SearchExaminerHubRep) {
      let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.Edit}`, examinerHub!.officeId)
      if (!hasEditPermission) { return }

      tableLoading.value = true
      try {
        let modifyExaminerHubCmd = new ModifyExaminerHubCmd({
          id: examinerHub.id,
          capacity: examinerHub.capacity,
          hubName: examinerHub.hubName!,
          abbreviation: examinerHub.abbreviation!,
          address: examinerHub.address!,
          isEnable: !examinerHub.isEnable,
        })
        await _Client.examinerHubClient.modify(modifyExaminerHubCmd)
        message.success(`${examinerHub.hubName} has been ${!examinerHub.isEnable ? 'Enabled' : 'Disabled'}`);
      } finally {
        tableLoading.value = false
      }
      await getList()
    }


    const AddOrModifyOperateion = () => {
      const createExaminerHubRef = ref(null)
      const modifyExaminerHubRef = ref(null)
      const showEditModal = ref<boolean>(false)
      let modifyState = reactive<{ examinerHubDetail: GetByIdExaminerHubRep }>({
        examinerHubDetail: new GetByIdExaminerHubRep()
      })
      //show
      async function showCreateModal() {
        (createExaminerHubRef.value as any).isShowCreateDialog = true
      };
      async function showModifyModal(examinerHub: SearchExaminerHubRep | null) {
        let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.Edit}`, examinerHub!.officeId)
        if (!hasEditPermission) { return }

        modifyState.examinerHubDetail = await _Client.examinerHubClient.getById(examinerHub!.id);
        showEditModal.value = true
      }
      //close
      async function closeAddModal(refresh: boolean = false) {
        showEditModal.value = false
        if (refresh) {
          await getList()
        }
      }
      async function closeModifyModal(refresh: boolean = false) {
        showEditModal.value = false
        if (refresh) {
          await getList()
        }
      }
      return { createExaminerHubRef, modifyExaminerHubRef, showEditModal, ...toRefs(modifyState), showCreateModal, showModifyModal, closeAddModal, closeModifyModal }
    }

    const logOperation = () => {
      const logDetailRef = ref(null)
      const logState = reactive<{ logSearchParames: SearchExaminerHubLogQuery, logDetail: LogDetailModel }>({
        logSearchParames: new SearchExaminerHubLogQuery(),
        logDetail: new LogDetailModel()
      })

      async function showLogDialog(examinerHub: SearchExaminerHubRep) {
        let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.ViewLog}`, examinerHub!.officeId)
        if (!hasEditPermission) { return }

        logState.logSearchParames = { ...logState.logSearchParames, pageIndex: 1, examinerHubId: examinerHub.id }
        await getLogList()
      }
      async function getLogList() {
        try {
          let logDetail = JSON.parse(JSON.stringify(await _Client.examinerHubClient.getExaminerHubLog(logState.logSearchParames)))
          logDetail.list = logDetail.list?.map((logItem: { createdOn: Date; operation: string; createdBy: string; }) => {
            return new LogModel({
              createdTime: logItem.createdOn,
              operation: logItem.operation,
              userName: logItem.createdBy
            })
          })
          logState.logDetail = new LogDetailModel(logDetail)
        }
        finally {
          (logDetailRef.value as any).showLogModal = true
        }
      }

      return { logDetailRef, ...toRefs(logState), showLogDialog, getLogList }
    }

    onMounted(async () => {
      await getList()
      await getRegionList()
    })

    return {
      momentTimeZone,
      statusList,
      columns,
      tableLoading,
      ...toRefs(listState),
      getList,
      changeSearchParames,
      resetSearchParams,
      disableHub,
      ...AddOrModifyOperateion(),
      ...logOperation()
    }
  }
})